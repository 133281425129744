import React, { FC } from 'react'

interface Props {
  text: string[]
  fontSizeEm?: number
}

/**
 * This component is shown in new tab as presentation.
 * It must use only plain HTML elements, no Material UI components.
 * @param props
 * @constructor
 */
const Slideshow: FC<Props> = (props) => {
  return (
    <>
      <div
        style={{
          background: 'black',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          fontFamily: "'Enriqueta', serif",
          fontSize: `${props.fontSizeEm}em`
        }}
      >
        <div style={{ textAlign: 'center' }}>
          {props.text.map((_) => (
            <>
              {_}
              <br />
            </>
          ))}
        </div>
      </div>
      <style>
        {`
        @import url(https://fonts.googleapis.com/css2?family=Enriqueta:wght@400;700&display=swap);
        
        body {
          margin: 0;
          padding: 0;
        }
      `}
      </style>
    </>
  )
}

export default Slideshow
