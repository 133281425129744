import React, { FC, ReactNode } from 'react'
import { InstantSearch } from 'react-instantsearch-hooks-web'
import { Hit } from 'instantsearch.js/es/types/results'
import { algoliaSearchClient } from '../../App'
import OwnSongsSearchResults, { SongHit } from './OwnSongsSearchResults'

interface Props {
  searchQuery: string
  renderSongItem: (song: Hit<SongHit>) => ReactNode
}

const OwnSongsSearch: FC<Props> = (props) => {
  return (
    <InstantSearch
      searchClient={algoliaSearchClient}
      indexName='prod_songs'
    >
      <OwnSongsSearchResults
        searchQuery={props.searchQuery}
        renderSongItem={props.renderSongItem}
      />
    </InstantSearch>
  )
}

export default OwnSongsSearch
