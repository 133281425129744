import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import SongListPage from './pages/SongListPage'
import Song4334Page from './pages/Song4334Page'
import PlaylistsPage from './pages/PlaylistsPage'
import { appUrls } from './helpers/urls'
import PlaylistPage from './pages/PlaylistPage'
import LoginPage from './pages/login/LoginPage'
import SignupPage from './pages/signup/SignupPage'
import { useAuthContext } from './hooks/useAuthContext'
import SongOwnPage from './pages/SongOwnPage'
import NewSongPage from './pages/NewSongPage'
import EditSongPage from './pages/EditSongPage'
import ProtectedRoute from './ProtectedRoute'
import PlaylistPresentPage from './pages/PlaylistPresentPage'

const AppRoutes: React.FC = () => {
  const {
    state: { user }
  } = useAuthContext()

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route
          path={appUrls.home}
          element={<Navigate to={appUrls.songList} />}
        />
        <Route
          path={appUrls.songList}
          element={<SongListPage />}
        />
        <Route
          path={appUrls.song4334.pattern}
          element={<Song4334Page />}
        />
        <Route
          path={appUrls.songOwn.pattern}
          element={<SongOwnPage />}
        />
        <Route
          path={appUrls.newSong}
          element={<NewSongPage />}
        />
        <Route
          path={appUrls.playlists}
          element={<PlaylistsPage />}
        />

        <Route
          path={appUrls.songOwnEdit.pattern}
          element={<EditSongPage />}
        />
      </Route>

      <Route
        path={appUrls.playlistPresent.pattern}
        element={<PlaylistPresentPage />}
      />

      <Route
        path={appUrls.playlist.pattern}
        element={<PlaylistPage />}
      />

      <Route
        path={appUrls.login}
        element={user ? <Navigate to={appUrls.home} /> : <LoginPage />}
      />
      <Route
        path={appUrls.signup}
        element={user ? <Navigate to={appUrls.home} /> : <SignupPage />}
      />
    </Routes>
  )
}

export default AppRoutes
