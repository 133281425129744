import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

interface SongTransposeState {
  transposeHowMuch: number // how much to transpose the chords
  transpose: (howMuch: number, startFromZero?: boolean) => void
  capo: number // auxiliary info for the musician about manual chord key change on the instrument
  setCapo: (capo: number) => void
}

interface SongTransposeContextValue extends SongTransposeState {
  transposeHowMuch: number
  transpose: (howMuch: number, startFromZero?: boolean) => void
  capo: number
  setCapo: (capo: number) => void
}

const SongTransposeContext = createContext<SongTransposeContextValue | undefined>(undefined)

export const SongTransposeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [transposeHowMuch, setTransposeHowMuch] = useState<number>(0)
  const [capo, setCapo] = useState<number>(0)

  const transpose = useCallback((howMuch: number, startFromZero?: boolean) => {
    setTransposeHowMuch((_) => (startFromZero ? howMuch : _ + howMuch))
  }, [])

  const setCapoCallback = useCallback((newCapo: number) => {
    setCapo(newCapo)
  }, [])

  const contextValue: SongTransposeContextValue = useMemo(
    () => ({
      transposeHowMuch,
      transpose,
      capo,
      setCapo: setCapoCallback
    }),
    [transposeHowMuch, transpose, capo, setCapoCallback]
  )

  return (
    <SongTransposeContext.Provider value={contextValue}>{children}</SongTransposeContext.Provider>
  )
}

export const useSongTransposeContext = () => {
  const context = useContext(SongTransposeContext)
  if (!context) {
    throw new Error('useSongTransposeContext must be used within a SongTransposeProvider')
  }

  return context
}
