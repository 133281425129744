import React, { FC } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import SongForm from '../components/own-songs/SongForm'
import Layout from '../components/layouts/Layout'
import { useEditOwnSongMutation, useFirebaseSongById } from '../data/queryFunctions'
import { ISong } from '../models/song'
import { appUrls } from '../helpers/urls'

export type EditSongPageParams = {
  songId: string
}

const EditSongPage: FC = () => {
  const params = useParams<EditSongPageParams>()
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: song, isLoading } = useFirebaseSongById(params.songId!)
  const { mutate: editSong } = useEditOwnSongMutation()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (data: Omit<ISong, 'id'>) => {
    if (!params.songId) {
      return
    }

    const newSong: ISong = {
      ...data,
      id: { id: params.songId, source: 'own' }
    }

    await editSong(newSong, {
      onSuccess: () => {
        enqueueSnackbar(`Pieseň ${data.title} bola úspešne upravená`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Nastala chyba pri ukladaní zmien', { variant: 'error' })
      }
    })

    navigate(appUrls.songList)
  }

  return (
    <Layout>
      <Typography
        variant={'h6'}
        sx={{ mb: 2 }}
      >
        Upraviť pieseň {song ? song.title : '...'}
      </Typography>

      {isLoading && <CircularProgress />}
      {song && (
        <SongForm
          onSubmit={onSubmit}
          song={song}
        />
      )}
    </Layout>
  )
}

export default EditSongPage
