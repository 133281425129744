import React, { FC } from 'react'
import { Stack } from '@mui/material'
import { lineContainsLyrics, SongLine } from './chordpro/SongRow'
import { SongDirective } from './chordpro/SongDirective'

interface Props {
  chordProText: string
}

const isDirective = (row: string): boolean => row.trim().startsWith('{')

const removeChordWPTags = (input: string): string => {
  return input.replace(/\[chordwp\]|\[\/chordwp\]|&nbsp;/g, '').replace(/\r\n/g, '\n')
}

const SongChordPro: FC<Props> = (props) => {
  const chordSheetRows = removeChordWPTags(props.chordProText).split('\n')

  const chordSheet = (() => {
    let isPreviousLineEmpty = false
    let isPreviousLineJustChords = false
    let index = 0

    return chordSheetRows.map((row) => {
      if (row.trim() === '') {
        isPreviousLineEmpty = true
        return null
      }

      if (!lineContainsLyrics(row)) {
        isPreviousLineJustChords = true
      }

      if (isDirective(row)) {
        const isPreviousLineEmptyBackup = isPreviousLineEmpty
        isPreviousLineEmpty = false
        return (
          <SongDirective
            key={index++}
            line={row}
            isPreviousLineEmpty={isPreviousLineEmptyBackup}
          />
        )
      }

      const isPreviousLineEmptyBackup = isPreviousLineEmpty
      const isPreviousLineJustChordsBackup = isPreviousLineJustChords
      isPreviousLineEmpty = false
      isPreviousLineJustChords = false
      return (
        <SongLine
          key={index++}
          line={row}
          isPreviousLineEmpty={isPreviousLineEmptyBackup}
          isPreviousLineJustChords={isPreviousLineJustChordsBackup}
        />
      )
    })
  })()

  return <Stack justifyContent={'center'}>{chordSheet}</Stack>
}

export default SongChordPro
