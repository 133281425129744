import { createTheme } from '@mui/material'

const textFont = 'Montserrat'

export const darkGray = '#1F1F1F'

export const lightTheme = createTheme({
  palette: {
    background: {
      default: '#fafafa'
    },
    primary: { main: '#44d480', light: '#a1e6d9' },
    secondary: { main: darkGray },
    error: { main: '#D7263D' }
  },
  typography: {
    fontFamily: `"${textFont}"`
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none'
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: darkGray
          }
        }
      }
    }
  }
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#18181A'
    },
    primary: { main: '#219150', light: '#a1e6d9' },
    secondary: { main: '#F0F8FF' }
  },
  typography: {
    fontFamily: `"${textFont}"`
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#F0F8FF'
          }
        }
      }
    }
  }
})
