import { useSnackbar } from 'notistack'
import { useEditPlaylistMutation } from '../data/queryFunctions'
import { ISong } from '../models/song'
import IPlaylist from '../models/playlist'

export const useAddSongToPlaylist = () => {
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: editPlaylist, isLoading: isEditingPlaylist } = useEditPlaylistMutation()

  const addSongToPlaylist = async (
    song: ISong,
    playlist: IPlaylist,
    transposeHowMuch: number,
    options?: { onSettled?: () => void }
  ) => {
    const playlistToEdit = { ...playlist }

    if (!playlistToEdit.songs) {
      playlistToEdit.songs = []
    }

    playlistToEdit.songs.push({
      id: song.id,
      transpose: transposeHowMuch,
      artist: null
    })

    await editPlaylist(playlistToEdit, {
      onSuccess: () => {
        enqueueSnackbar(`Pieseň ${song.title} bola pridaná do playlistu ${playlist.name}`, {
          variant: 'success'
        })
      },
      onError: () => {
        enqueueSnackbar(`Pieseň ${song.title} sa nepodarilo pridať do playlistu ${playlist.name}`, {
          variant: 'error'
        })
      }
    })

    options?.onSettled?.()
  }

  return {
    addSongToPlaylist,
    isEditingPlaylist
  }
}
