import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

interface SongState {
  isChordsVisible: boolean
  isDirectivesVisible: boolean
  fontSize: number
}

interface SongContextValue extends SongState {
  toggleChordsVisibility: () => void
  toggleDirectivesVisibility: () => void
  setFontSize: (size: number) => void
}

const SongContext = createContext<SongContextValue | undefined>(undefined)

export const SongProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isChordsVisible, setIsChordsVisible] = useState<boolean>(false)
  const [isDirectivesVisible, setIsDirectivesVisible] = useState<boolean>(false)
  const [fontSize, setFontSize] = useState<number>(1.2)

  const toggleChordsVisibility = useCallback(() => {
    setIsChordsVisible((_) => !_)
  }, [])

  const toggleDirectivesVisibility = useCallback(() => {
    setIsDirectivesVisible((_) => !_)
  }, [])

  const contextValue: SongContextValue = useMemo(
    () => ({
      isChordsVisible,
      isDirectivesVisible,
      fontSize,
      toggleChordsVisibility,
      toggleDirectivesVisibility,
      setFontSize
    }),
    [
      isChordsVisible,
      isDirectivesVisible,
      fontSize,
      toggleChordsVisibility,
      toggleDirectivesVisibility,
      setFontSize
    ]
  )

  return <SongContext.Provider value={contextValue}>{children}</SongContext.Provider>
}

export const useSongContext = () => {
  const context = useContext(SongContext)
  if (!context) {
    throw new Error('useSongContext must be used within a SongProvider')
  }
  return context
}
