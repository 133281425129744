import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useReducer
} from 'react'
import { User } from 'firebase/auth'
import { projectAuth } from '../firebase/config'

export type AppUser = User

export const LOGIN = 'auth/login'
export const LOGOUT = 'auth/logout'
export const AUTH_IS_READY = 'auth/auth-is-ready'

export interface LoginAction {
  type: typeof LOGIN
  value: AppUser
}

export interface LogoutAction {
  type: typeof LOGOUT
}

export interface AuthIsReadyAction {
  type: typeof AUTH_IS_READY
  value: AppUser | undefined
}

export interface AuthState {
  user: AppUser | undefined
  authIsReady: boolean
}

type AuthActionType = LoginAction | LogoutAction | AuthIsReadyAction

export const AuthContext = createContext<{ state: AuthState; dispatch: Dispatch<AuthActionType> }>({
  state: {
    user: undefined,
    authIsReady: false
  },
  dispatch: () => {}
})

export const AuthReducer: (state: AuthState, action: AuthActionType) => AuthState = (
  state,
  action
) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, user: action.value }
    case LOGOUT:
      return { ...state, user: undefined }
    case AUTH_IS_READY:
      return { ...state, user: action.value, authIsReady: true }
    default:
      return state
  }
}

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, {
    user: undefined,
    authIsReady: false
  })

  useEffect(() => {
    const unsub = projectAuth.onAuthStateChanged((user) => {
      dispatch({ type: AUTH_IS_READY, value: user ?? undefined })
      unsub()
    })
  }, [])

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
