import { useContext, useState, MouseEvent } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import PersonIcon from '@mui/icons-material/Person'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import QueueMusicIcon from '@mui/icons-material/QueueMusic'
import SlideshowIcon from '@mui/icons-material/Slideshow'
import LightModeIcon from '@mui/icons-material/LightMode'
import NightlightRoundIcon from '@mui/icons-material/NightlightRound'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import { MUIWrapperContext } from '../App'
import { darkGray } from '../config/muiTheme'
import { useSlideshowContext } from '../context/SlideshowContext'
import { Logo } from './header/Logo'
import { useAuthContext } from '../hooks/useAuthContext'
import { useLogout } from '../hooks/useLogout'
import { appUrls } from '../helpers/urls'

const pages = [
  {
    name: 'Piesne',
    url: appUrls.songList,
    isPrivate: true,
    icon: <MusicNoteIcon />
  },
  {
    name: 'Playlisty',
    url: appUrls.playlists,
    isPrivate: true,
    icon: <QueueMusicIcon />
  }
]

function Nav() {
  const navigate = useNavigate()
  const theme = useTheme()
  const muiUtils = useContext(MUIWrapperContext)
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const { logout } = useLogout()
  const {
    state: { user }
  } = useAuthContext()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const { state: slideshowState } = useSlideshowContext()

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const goTo = (url: string) => {
    navigate(url)
  }

  return (
    <AppBar
      position='static'
      sx={{ background: darkGray }}
    >
      <Container maxWidth={'xl'}>
        <Toolbar disableGutters>
          <Logo sx={{ mr: 4 }} />

          <Stack
            direction={'row'}
            gap={1}
            sx={{ flexGrow: 1, justifyContent: 'end', display: { xs: 'flex', md: 'flex' } }}
          >
            {pages.map(
              (page) =>
                (!page.isPrivate || user) && (
                  <Button
                    key={page.url}
                    color={'secondary'}
                    startIcon={page.icon}
                    onClick={() => goTo(page.url)}
                  >
                    {!isSmallScreen && page.name}
                  </Button>
                )
            )}

            {slideshowState?.currentWindow && (
              <Button
                color={'secondary'}
                startIcon={<SlideshowIcon />}
                onClick={() =>
                  goTo(
                    appUrls.playlistPresent.withParams({ playlistId: slideshowState.playlistId })
                  )
                }
              >
                {!isSmallScreen && 'Prezentácia'}
              </Button>
            )}
          </Stack>

          <Box
            sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'flex', md: 'none' } }}
          >
            <IconButton
              size='large'
              onClick={handleOpenNavMenu}
              sx={{ color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'block' }
              }}
            >
              <MenuItem
                onClick={() => {
                  muiUtils.toggleColorMode()
                  handleCloseUserMenu()
                }}
              >
                {theme.palette.mode === 'dark' ? (
                  <>
                    <LightModeIcon sx={{ mr: 1 }} />
                    Svetlý režim
                  </>
                ) : (
                  <>
                    <NightlightRoundIcon sx={{ mr: 1 }} />
                    Tmavý režim
                  </>
                )}
              </MenuItem>
              <MenuItem onClick={logout}>
                <LogoutRoundedIcon sx={{ mr: 1 }} />
                Odhlásiť sa
              </MenuItem>
            </Menu>
          </Box>

          {/* if user is authenticated show this */}
          {user && (
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title='Open settings'>
                <Button
                  startIcon={<PersonIcon />}
                  onClick={handleOpenUserMenu}
                  color={'secondary'}
                >
                  {user.email}
                </Button>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    muiUtils.toggleColorMode()
                    handleCloseUserMenu()
                  }}
                >
                  {theme.palette.mode === 'dark' ? (
                    <>
                      <LightModeIcon sx={{ mr: 1 }} />
                      Svetlý režim
                    </>
                  ) : (
                    <>
                      <NightlightRoundIcon sx={{ mr: 1 }} />
                      Tmavý režim
                    </>
                  )}
                </MenuItem>
                <MenuItem onClick={logout}>
                  <LogoutRoundedIcon sx={{ mr: 1 }} />
                  Odhlásiť sa
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Nav
