import { SongPageParams } from '../pages/Song4334Page'
import { SongId } from '../models/playlist'
import { PlaylistPageParams } from '../pages/PlaylistPage'
import { PlaylistPresentPageParams } from '../pages/PlaylistPresentPage'

export const appUrls = {
  home: '/',
  songList: '/songlist',
  registration: '/registration',
  song4334: {
    pattern: '/song/4334/:songId',
    withParams: (params: SongPageParams): string => `/song/4334/${params.songId}`
  },
  songOwn: {
    pattern: '/song/own/:songId',
    withParams: (params: SongPageParams): string => `/song/own/${params.songId}`
  },
  songOwnEdit: {
    pattern: '/song/own/:songId/edit',
    withParams: (params: SongPageParams): string => `/song/own/${params.songId}/edit`
  },
  newSong: '/new-song',
  playlists: '/playlists',
  playlist: {
    pattern: '/playlists/:playlistId',
    withParams: (params: PlaylistPageParams): string => `/playlists/${params.playlistId}`
  },
  playlistPresent: {
    pattern: '/playlists/:playlistId/present/',
    withParams: (params: PlaylistPresentPageParams): string =>
      `/playlists/${params.playlistId}/present`
  },
  login: '/login',
  signup: '/signup'
}

export const getUrlForSong = (id: SongId): string => {
  switch (id.source) {
    case '4334':
      return appUrls.song4334.withParams({ songId: id.id })
    case 'own':
      return appUrls.songOwn.withParams({ songId: id.id })
    default:
      throw new Error(`Unknown song type ${id.source}`)
  }
}
