import React, { FC } from 'react'
import { CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { appUrls } from '../helpers/urls'
import OwnSongsSearch from '../components/own-songs/OwnSongsSearch'
import { songIdToString } from '../helpers/utils'
import { use4334SongsSearch } from '../data/queryFunctions'
import Layout from '../components/layouts/Layout'
import SearchSongInput from '../SearchSongInput'
import SongListItemWithAddToPlaylistDialog from '../components/song/SongListItemWithAddToPlaylistDialog'

const searchParamName = 'q'
const pageParamName = 'page'

const SongListPage: FC = () => {
  const navigate = useNavigate()

  const searchText = new URLSearchParams(window.location.search).get(searchParamName) ?? ''
  const pageParam = new URLSearchParams(window.location.search).get(pageParamName)
  const page = pageParam ? parseInt(pageParam, 10) : 1

  const { data: songs, isFetching } = use4334SongsSearch(searchText, page)
  const { data: songsNextPage, isFetching: isFetchingNextPage } = use4334SongsSearch(
    searchText,
    page + 1
  )

  const setPage = (newPage: number) => {
    navigate(`${appUrls.songList}?${searchParamName}=${searchText}&${pageParamName}=${newPage}`)
  }

  return (
    <Layout>
      <Grid
        container
        spacing={5}
      >
        <Grid
          item
          xs={12}
        >
          <SearchSongInput
            searchText={searchText}
            url={appUrls.songList}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
          <Typography
            variant={'h6'}
            sx={{ mb: 2 }}
          >
            Piesne zo 4334
          </Typography>
          <Grid
            container
            spacing={2}
          >
            {isFetching ? (
              <CircularProgress />
            ) : (
              songs?.map((_) => (
                <Grid
                  key={songIdToString(_.id)}
                  item
                  xs={12}
                  md={6}
                >
                  <SongListItemWithAddToPlaylistDialog song={_} />
                </Grid>
              ))
            )}

            <Grid
              item
              sm={12}
            >
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={2}
              >
                <IconButton
                  onClick={() => setPage(page - 1)}
                  disabled={isFetching || page === 1}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <Typography>Strana: {page}</Typography>
                <IconButton
                  onClick={() => setPage(page + 1)}
                  disabled={isFetchingNextPage || songsNextPage?.length === 0}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={2}
            sx={{ mb: 2 }}
          >
            <Typography variant={'h6'}>Vlastné piesne</Typography>

            <Button
              onClick={() => navigate(appUrls.newSong)}
              variant={'contained'}
              sx={{ ml: 1 }}
            >
              Pridať pieseň
            </Button>
          </Stack>

          <OwnSongsSearch
            searchQuery={searchText ?? ''}
            renderSongItem={(_) => (
              <SongListItemWithAddToPlaylistDialog
                song={{
                  ..._,
                  id: {
                    id: _.objectID,
                    source: 'own'
                  }
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default SongListPage
