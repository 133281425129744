import React, { FC, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import {
  DialogTitle,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Typography
} from '@mui/material'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { useCreatePlaylistMutation, useFirebasePlaylistsOfUser } from '../../data/queryFunctions'
import { appUrls } from '../../helpers/urls'
import { useAuthContext } from '../../hooks/useAuthContext'
import { ISong } from '../../models/song'
import { useAddSongToPlaylist } from '../../hooks/useAddSongToPlaylist'

export interface Props {
  song: ISong
  open: boolean
  onClose: () => void
  transpose?: number
}

const ManagePlaylistsDialog: FC<Props> = (props) => {
  const navigate = useNavigate()
  const {
    state: { user }
  } = useAuthContext()

  if (!user) {
    navigate(appUrls.login)
  }

  const { enqueueSnackbar } = useSnackbar()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: playlists } = useFirebasePlaylistsOfUser(user!.uid)
  const { mutate: addPlaylist, isLoading: isCreatingPlaylist } = useCreatePlaylistMutation()

  const { addSongToPlaylist, isEditingPlaylist } = useAddSongToPlaylist()

  const [showInput, setShowInput] = useState(false)

  const [namePlaylist, setNamePlaylist] = useState<string | undefined>('')

  const sortedPlaylists = useMemo(
    () => [...(playlists ?? [])].sort((a, b) => a.name.localeCompare(b.name)),
    [playlists]
  )

  const addNewPlaylist = async () => {
    if (!namePlaylist || !user) {
      return
    }

    await addPlaylist(
      {
        name: namePlaylist,
        songs: [],
        createdAt: new Date(),
        owners: [user.uid]
      },
      {
        onSuccess: () => {
          enqueueSnackbar(`Playlist ${namePlaylist} bol vytvorený`, {
            variant: 'success'
          })
        },
        onError: () => {
          enqueueSnackbar(`Playlist ${namePlaylist} sa nepodarilo vytvoriť`, {
            variant: 'error'
          })
        }
      }
    )

    setShowInput(false)
  }

  const handleClose = () => {
    props.onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle>Pridať do playlistu</DialogTitle>
      <List>
        {sortedPlaylists !== undefined &&
          sortedPlaylists.map((_) => (
            <ListItem
              key={_.id}
              disablePadding
            >
              <ListItemButton
                onClick={() =>
                  addSongToPlaylist(props.song, _, props.transpose ?? 0, { onSettled: handleClose })
                }
                disabled={isEditingPlaylist}
              >
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText primary={_.name} />
              </ListItemButton>
            </ListItem>
          ))}

        {showInput && (
          <ListItem
            id='inputText'
            sx={{ mt: 2 }}
          >
            <FormControl
              fullWidth
              size={'small'}
              variant='standard'
            >
              <OutlinedInput
                id='results'
                placeholder={'Zadajte názov nového playlistu'}
                value={namePlaylist ?? ''}
                onChange={(_) => setNamePlaylist(_.target.value)}
                label='Názov playlistu'
              />
            </FormControl>
          </ListItem>
        )}
        <ListItem>
          {!showInput ? (
            <Button onClick={() => setShowInput(true)}>
              <Typography>Pridať nový</Typography>
            </Button>
          ) : (
            <LoadingButton
              loading={isCreatingPlaylist}
              variant={'contained'}
              onClick={addNewPlaylist}
            >
              <Typography>Uložiť</Typography>
            </LoadingButton>
          )}
        </ListItem>
      </List>
    </Dialog>
  )
}
export default ManagePlaylistsDialog
