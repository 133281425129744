import React, { createContext, FC, useMemo } from 'react'
import './assets/css/chordwp-propack.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { SnackbarProvider } from 'notistack'
import { useLocalStorage } from 'usehooks-ts'
import AppRoutes from './AppRoutes'
import { darkTheme, lightTheme } from './config/muiTheme'
import { getAlgoliaSearchClient } from './config/algolia'
import { SlideshowContextProvider } from './context/SlideshowContext'
import { SongProvider } from './context/SongContext'

export const MUIWrapperContext = createContext({
  toggleColorMode: () => {}
})

export default function MUIWrapper({ children }: { children: React.ReactNode }) {
  const [mode, setMode] = useLocalStorage('color-mode', 'light')

  const muiWrapperUtils = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      }
    }),
    [setMode]
  )

  const theme = mode === 'light' ? lightTheme : darkTheme

  return (
    <MUIWrapperContext.Provider value={muiWrapperUtils}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MUIWrapperContext.Provider>
  )
}

const queryClient = new QueryClient()
export const algoliaSearchClient = getAlgoliaSearchClient()
export const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MUIWrapper>
        <CssBaseline />
        <SongProvider>
          <SnackbarProvider maxSnack={3}>
            <Router>
              <SlideshowContextProvider>
                <AppRoutes />
              </SlideshowContextProvider>
            </Router>
          </SnackbarProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </SongProvider>
      </MUIWrapper>
    </QueryClientProvider>
  )
}
