import React, { FC } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import PlaylistItem from '../PlaylistItem'
import { useLatestPlaylists } from '../../hooks/useLatestPlaylists'
import { appUrls } from '../../helpers/urls'

const LatestPlaylists: FC = () => {
  const navigate = useNavigate()
  const { latestPlaylists, addLatestPlaylist } = useLatestPlaylists()
  const goToPlaylist = (id: string, name: string) => {
    addLatestPlaylist(id, name)
    navigate(appUrls.playlist.withParams({ playlistId: id }))
  }

  return (
    <Stack
      direction={'column'}
      gap={1}
    >
      {latestPlaylists.length > 0 && <Typography variant={'h5'}>Posledné playlisty</Typography>}

      <Grid
        container
        spacing={1}
      >
        {latestPlaylists.map((_) => (
          <Grid
            key={_.id}
            item
            xs={12}
            md={4}
          >
            <PlaylistItem
              key={_.id}
              playlistId={_.id}
              name={_.name}
              startPlaylist={() => goToPlaylist(_.id, _.name)}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}

export default LatestPlaylists
