import React from 'react'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { use4334SongsSearch } from '../../data/queryFunctions'
import { songIdToString } from '../../helpers/utils'
import SongListItem from '../SongListItem'
import OwnSongsSearch from '../own-songs/OwnSongsSearch'
import SearchSongInput from '../../SearchSongInput'
import IPlaylist from '../../models/playlist'
import { useAddSongToPlaylist } from '../../hooks/useAddSongToPlaylist'
import { ISong } from '../../models/song'

export interface Props {
  playlist: IPlaylist
  open: boolean
  onClose: () => void
}

const searchParamName = 'q'

function AddSongToPlaylist({ playlist, open, onClose }: Props) {
  const searchText = new URLSearchParams(window.location.search).get(searchParamName) ?? ''
  const currentUrl = window.location.pathname
  const { data: songs, isFetching } = use4334SongsSearch(searchText, 1)

  const { addSongToPlaylist } = useAddSongToPlaylist()

  const onClick = async (song: ISong) => {
    await addSongToPlaylist(song, playlist, 0, { onSettled: onClose })
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth='xl'
    >
      <DialogTitle>
        <Stack
          width={1}
          direction={'row'}
          spacing={2}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography>Pridať pieseň do playlistu</Typography>
          <IconButton
            color='secondary'
            onClick={handleClose}
            sx={{ mt: 1 }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          spacing={5}
        >
          <Grid
            item
            xs={12}
          >
            <SearchSongInput
              searchText={searchText}
              url={currentUrl}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ mb: 2 }}
          >
            <Typography
              variant='h5'
              sx={{ mb: 2 }}
            >
              Piesne zo 4334
            </Typography>

            <Grid
              container
              spacing={2}
            >
              {isFetching ? (
                <CircularProgress />
              ) : (
                songs?.map((_) => (
                  <Grid
                    key={songIdToString(_.id)}
                    item
                    xs={12}
                    md={6}
                  >
                    <SongListItem
                      song={_}
                      customOnClick={() => onClick(_)}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              sx={{ mb: 2 }}
              variant='h5'
            >
              Vlastné piesne
            </Typography>

            <OwnSongsSearch
              searchQuery={searchText ?? ''}
              renderSongItem={(_) => (
                <SongListItem
                  song={{
                    ..._,
                    id: {
                      id: _.objectID,
                      source: 'own'
                    }
                  }}
                  customOnClick={() =>
                    onClick({
                      ..._,
                      id: {
                        id: _.objectID,
                        source: 'own'
                      }
                    })
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default AddSongToPlaylist
