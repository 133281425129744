import React from 'react'

import { useForm } from 'react-hook-form'
import { Grid, Stack, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router'
import Button from '@mui/material/Button'
import { appUrls } from '../../helpers/urls'
import Layout from '../../components/layouts/Layout'
import { useSignup } from '../../hooks/useSignup'

interface FormData {
  email: string
  password: string
  displayName: string
}

export default function SignupPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FormData>({ mode: 'onBlur' })

  const navigate = useNavigate()
  const { /* signup, */ error } = useSignup()

  const onSubmit = async (data: FormData) => {
    // await signup(data.email, data.password, data.displayName)
    navigate(appUrls.login)
  }

  return (
    <Layout>
      <Grid container>
        <Grid
          item
          xs={0}
          md={3}
        />
        <Grid
          item
          xs={12}
          md={6}
        >
          <Typography
            variant='h4'
            sx={{ mt: 2, mb: 4 }}
          >
            Registrácia
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <TextField
                label='Email'
                {...register('email', { required: true })}
                error={!!errors.email}
                helperText={errors.email && 'Toto pole je povinné'}
              />
              <TextField
                label='Meno'
                {...register('displayName', { required: true })}
                error={!!errors.displayName}
                helperText={errors.displayName && 'Toto pole je povinné'}
              />
              <TextField
                label='Heslo'
                type={'password'}
                {...register('password', { required: true })}
                error={!!errors.password}
                helperText={errors.password && 'Toto pole je povinné'}
              />
            </Stack>

            <LoadingButton
              type='submit'
              variant='contained'
              loading={isSubmitting}
              sx={{ mt: 2, mr: 1 }}
            >
              Registrovať
            </LoadingButton>

            <Button
              color={'secondary'}
              variant={'outlined'}
              sx={{ mt: 2 }}
              onClick={() => navigate(appUrls.login)}
            >
              Späť na prihlásenie
            </Button>
            {error && <p>{error}</p>}
          </form>
        </Grid>
      </Grid>
    </Layout>
  )
}
