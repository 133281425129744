import React from 'react'

import { Grid, Stack, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import Layout from '../../components/layouts/Layout'
import { useLogin } from '../../hooks/useLogin'
// import { appUrls } from '../../helpers/urls'
// import Button from '@mui/material/Button'
// import { useNavigate } from 'react-router'

interface FormData {
  email: string
  password: string
}

export default function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FormData>({ mode: 'onBlur' })

  // const navigate = useNavigate()
  const { login, error } = useLogin()

  const onSubmit = async (data: FormData) => {
    await login(data.email, data.password)
  }

  return (
    <Layout>
      <Grid container>
        <Grid
          item
          xs={0}
          md={3}
        />
        <Grid
          item
          xs={12}
          md={6}
        >
          <Typography
            variant='h4'
            sx={{ mt: 2, mb: 4 }}
          >
            Prihlásenie
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <TextField
                label='Email'
                {...register('email', { required: true })}
                error={!!errors.email}
                helperText={errors.email && 'Toto pole je povinné'}
              />
              <TextField
                label='Heslo'
                type={'password'}
                {...register('password', { required: true })}
                error={!!errors.password}
                helperText={errors.password && 'Toto pole je povinné'}
              />
            </Stack>

            <LoadingButton
              type='submit'
              variant='contained'
              loading={isSubmitting}
              sx={{ mt: 2, mr: 1 }}
            >
              Prihlásiť sa
            </LoadingButton>

            {/* <Button */}
            {/*  color={'secondary'} */}
            {/*  variant={'outlined'} */}
            {/*  sx={{ mt: 2 }} */}
            {/*  onClick={() => navigate(appUrls.signup)} */}
            {/* > */}
            {/*  Chcem sa registrovať */}
            {/* </Button> */}
            {error && <p>{error}</p>}
          </form>
        </Grid>
      </Grid>
    </Layout>
  )
}
