import React, { useState } from 'react'
import { InputBase, Paper } from '@mui/material'
import { useNavigate } from 'react-router'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'

export interface Props {
  url: string
  searchText: string
}

const searchParamName = 'q'

function SearchSongInput({ searchText, url }: Props) {
  const navigate = useNavigate()
  const [inputText, setInputText] = useState<string>()

  const onSearchSong = () => {
    if (inputText === undefined) {
      return
    }

    navigate(`${url}?${searchParamName}=${inputText}`)
  }

  return (
    <Paper
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={'Zadaj názov / text piesne'}
        defaultValue={searchText}
        onChange={(event) => setInputText(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSearchSong()
            event.preventDefault()
          }
        }}
      />
      <IconButton
        type='button'
        sx={{ p: '10px' }}
        onClick={onSearchSong}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchSongInput
