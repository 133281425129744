export const chordsMajor = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'H']

export const getChordIndex = (chordTone: string) => {
  switch (chordTone.toUpperCase()) {
    case 'C':
      return 0
    case 'C#':
      return 1
    case 'D':
      return 2
    case 'D#':
      return 3
    case 'E':
      return 4
    case 'F':
      return 5
    case 'F#':
      return 6
    case 'G':
      return 7
    case 'G#':
      return 8
    case 'A':
      return 9
    case 'A#':
      return 10
    case 'H':
      return 11
    default:
      throw new Error(`Unknown chord: ${chordTone}`)
  }
}

/**
 * We store chord key as C, C#, D, D#, E, F, F#, G, G#, A, A#, H (or lowercase for minor chords)
 * but from 4334 we sometimes get Cdur, C#dur, etc.
 */
export const getStandardChordKey = (chordKey: string): string => {
  const normalizedInput = chordKey.toLowerCase() // Convert input to lowercase

  // Check if the input contains "mi," "mol," or "dur"
  if (normalizedInput.includes('mi')) {
    return normalizedInput.replace(/\bmi\b/gi, '') // Remove 'mi' and return lowercase
  }
  if (normalizedInput.includes('mol')) {
    return normalizedInput.replace(/\bmol\b/gi, '') // Remove 'mol' and return lowercase
  }
  if (normalizedInput.includes('dur')) {
    const chordWithoutModifiers = normalizedInput.replace(/dur/gi, '') // Remove 'dur'
    return chordWithoutModifiers.charAt(0).toUpperCase() + chordWithoutModifiers.slice(1) // Capitalize first letter
  }
  return chordKey
}

export const transposeChord = (chord: string, howMuch: number) => {
  if (!chord) return chord

  try {
    const { chordFlavor, chordIndex, isMajor } = analyzeChord(chord)

    const newChordIndex = chordIndex + howMuch
    let newChord = chordsMajor[(newChordIndex + 12) % 12]
    newChord = isMajor ? newChord : newChord.toLowerCase()

    return newChord + chordFlavor
  } catch (error) {
    return chord
  }
}

export const analyzeChord = (chord: string) => {
  const standardChord = getStandardChordKey(chord)

  const toneExtracted = standardChord.match(/^[a-zA-Z][#]?/g) || standardChord.charAt(0)
  const chordTone: string = toneExtracted && toneExtracted[0]
  const chordIndex = getChordIndex(chordTone)
  const isMajor = isChordMajor(chordTone)
  const chordFlavor = standardChord.slice(toneExtracted[0].length || 1)

  return {
    chordTone,
    chordIndex,
    isMajor,
    chordFlavor
  }
}

export const isChordMajor = (chord: string) =>
  chord.charCodeAt(0) >= 65 && chord.charCodeAt(0) <= 90
