import React, { FC, PropsWithChildren } from 'react'
import { Grid, Stack } from '@mui/material'
import Nav from '../Nav'

const Layout: FC<PropsWithChildren> = (props) => {
  return (
    <Stack
      dir={'column'}
      alignItems={'center'}
    >
      <Nav />

      <Grid
        container
        maxWidth={'xl'}
        justifyContent={'center'}
      >
        <Grid
          item
          xs={12}
          py={3}
          px={2}
        >
          {props.children}
        </Grid>
      </Grid>
    </Stack>
  )
}

export default Layout
