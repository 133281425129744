import { addDoc, deleteDoc, doc, updateDoc } from '@firebase/firestore'
import { playlistCollection, slideshowCollection, songsCollection } from '../firebase/config'
import IPlaylist from '../models/playlist'
import { ISong } from '../models/song'
import { ISlideshow } from '../models/slideshow'

export const addSongToFirestore = async (song: Omit<ISong, 'id'>) => {
  const documentRef = await addDoc(songsCollection, song)

  const songWithId: ISong = {
    ...song,
    id: { id: documentRef.id, source: 'own' }
  }

  return songWithId
}

export const editSongInFirestore = async (song: ISong) => {
  const songDoc = doc(songsCollection, song.id.id)
  await updateDoc(songDoc, song)

  return song
}

export const deleteSongFromFirestore = async (id: string) => {
  const songDoc = doc(songsCollection, id)
  await deleteDoc(songDoc)

  return id
}

export const addPlaylistToFirestore = async (playlist: Omit<IPlaylist, 'id'>) => {
  const documentRef = await addDoc(playlistCollection, playlist)

  const playlistWithId: IPlaylist = {
    ...playlist,
    id: documentRef.id
  }

  return playlistWithId
}

export const editPlaylistInFirestore = async (playlist: IPlaylist) => {
  const recipeDoc = doc(playlistCollection, playlist.id)
  await updateDoc(recipeDoc, playlist)

  return playlist
}

export const deletePlaylistFromFirestore = async (id: string) => {
  const recipeDoc = doc(playlistCollection, id)
  await deleteDoc(recipeDoc)

  return id
}

export const addSlideshowToFirestore = async (slideshow: Omit<ISlideshow, 'id'>) => {
  const documentRef = await addDoc(slideshowCollection, slideshow)

  const slideshowWithId: ISlideshow = {
    ...slideshow,
    id: documentRef.id
  }

  return slideshowWithId
}

export const editSlideshowInFirestore = async (slideshow: ISlideshow) => {
  const slideshowDoc = doc(slideshowCollection, slideshow.id)
  await updateDoc(slideshowDoc, slideshow)

  return slideshow
}
