import React from 'react'
import { Stack } from '@mui/material'
import { TextWithChord } from './ChordProElements'
import { useSongContext } from '../../context/SongContext'

const getPreviousChar = (row: string, index: number) => {
  if (index === 0) return ''
  return row[index - 1]
}

const getFollowingChar = (row: string, index: number) => {
  if (index === row.length - 1) return ''
  return row[index + 1]
}

export const lineContainsLyrics = (line: string): boolean => {
  const noChordsLine = line.replace(/\[[^\]]+\]/g, '').trim()
  return noChordsLine.length > 0
}

const lineContainsChords = (line: string): boolean => line.includes('[') && line.includes(']')

const isInTextFollowingChord = (line: string, index: number) => {
  let isInChord = false

  for (let i = index; i < line.length; i += 1) {
    if (line[i] === '[') {
      isInChord = true
      continue
    } else if (line[i] === ']') {
      isInChord = false
      continue
    }

    if (!isInChord && line[i] === ' ') {
      return false
    }

    if (!isInChord && line[i] !== ' ') {
      return true
    }
  }

  return false
}

interface SongRowProps {
  line: string
  isPreviousLineEmpty: boolean
  isPreviousLineJustChords: boolean
}

export const SongLine: React.FC<SongRowProps> = ({
  line,
  isPreviousLineEmpty,
  isPreviousLineJustChords
}) => {
  const { isChordsVisible, fontSize } = useSongContext()

  if (!isChordsVisible && !lineContainsLyrics(line)) {
    return null
  }

  const setMinimumHeight = lineContainsChords(line) && isChordsVisible && lineContainsLyrics(line)

  // eslint-disable-next-line no-undef
  const elements: JSX.Element[] = []
  let buffer = ''
  let chordBuffer = ''
  let chordsBuffer: string[] = []
  let index = 0

  const flushChordsBuffer = () => {
    if (chordsBuffer.length === 0 && buffer.length === 0) {
      return
    }

    const followingChar = getFollowingChar(line, index)
    const previousChar = getPreviousChar(line, index)

    const hasDash = previousChar !== ' ' && !!followingChar && isInTextFollowingChord(line, index)
    const hasRightSpace = previousChar === ' ' && !!followingChar
    elements.push(
      <TextWithChord
        key={index}
        chord={chordsBuffer}
        text={buffer}
        includeDash={hasDash}
        withRightSpace={hasRightSpace}
      />
    )
    chordsBuffer = []
    buffer = ''
  }

  while (index < line.length) {
    const char = line[index]

    if (char === '[') {
      if (getPreviousChar(line, index) !== ']') {
        flushChordsBuffer()
      }

      index += 1
      while (index < line.length && line[index] !== ']') {
        chordBuffer += line[index]
        index += 1
      }
      chordsBuffer.push(chordBuffer)
      chordBuffer = ''

      if (getFollowingChar(line, index) === ' ') {
        flushChordsBuffer()
      }
    } else {
      buffer += char
    }
    index += 1
  }

  flushChordsBuffer()

  if (buffer.length > 0) {
    elements.push(
      <TextWithChord
        key={index}
        chord={chordsBuffer}
        text={buffer}
        includeDash={false}
      />
    )
  }

  return (
    <>
      {isPreviousLineEmpty && <br />}
      <Stack
        direction={'row'}
        justifyContent={'center'}
        sx={{
          minHeight: setMinimumHeight ? `${(fontSize + 0.05) * 2}em` : 'auto',
          mt: isPreviousLineJustChords ? '0.5em' : 0
        }}
      >
        {elements}
      </Stack>
    </>
  )
}
