import { FC } from 'react'
import { Avatar, SxProps } from '@mui/material'
import { PlaylistArtist } from '../../../models/playlist'

export interface Props {
  artist: PlaylistArtist
  size: 'small' | 'medium'
  sx?: SxProps
}

const SongArtistAvatar: FC<Props> = ({ artist, size, sx }) => {
  const widthHeight = size === 'small' ? 25 : 30

  return (
    <Avatar
      sx={{
        bgcolor: artist.color,
        width: widthHeight,
        height: widthHeight,
        fontSize: '0.9em',
        ...sx
      }}
    >
      {artist.shortName ?? artist.name[0]}
    </Avatar>
  )
}

export default SongArtistAvatar
