import React from 'react'
import Box from '@mui/material/Box'
import { Stack, Typography } from '@mui/material'
import { useSongContext } from '../../context/SongContext'
import { transposeChord } from '../../helpers/chords'
import { useSongTransposeContext } from '../../context/SongTransposeContext'

type ChordProps = {
  chord: string
}

type TextProps = {
  text: string
}

type TagProps = {
  tag: string
  value: string
}

const Chord: React.FC<ChordProps> = ({ chord }) => {
  const { isChordsVisible, fontSize } = useSongContext()
  const { transposeHowMuch } = useSongTransposeContext()
  const transposeNotes = (transposeHowMuch + 12) % 12

  if (!isChordsVisible) {
    return null
  }

  const transpose = (chordToTranspose: string, howMuch: number): string => {
    if (chordToTranspose.includes('/')) {
      const chordSplit = chordToTranspose.split('/')
      return `${transpose(chordSplit[0], howMuch)}/${transpose(chordSplit[1], howMuch)}`
    }

    return transposeChord(chordToTranspose, howMuch)
  }

  const chordTransposed = transpose(chord, transposeNotes)

  return (
    <Box
      sx={{
        display: 'flex',
        width: 'fit-content',
        fontWeight: 'bold',
        backgroundColor: 'rgba(54, 218, 83, 0.3)',
        borderRadius: '2px',
        fontSize: `${fontSize - 0.1}em`,
        padding: '0.1em 0.2em',
        marginRight: '0.1em',
        marginBottom: '-0.3em',
        lineHeight: '1em',
        verticalAlign: 'super',
        fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
        fontStyle: 'normal'
      }}
    >
      {chordTransposed}
    </Box>
  )
}

const Text: React.FC<TextProps> = ({ text }) => {
  return <Typography>{text}</Typography>
}

interface TextWithChordProps {
  text: string
  chord?: string[]
  includeDash: boolean
  withRightSpace?: boolean
}

const TextWithChord: React.FC<TextWithChordProps> = (props) => {
  const { fontSize } = useSongContext()
  const stackJustifyContent = props.chord && !props.text ? 'flex-start' : 'flex-end'
  const withDashOverride = props.includeDash && props.text

  return (
    <Stack
      direction={'column'}
      justifyContent={stackJustifyContent}
      sx={{ mr: props.withRightSpace ? '0.4em' : 0 }}
    >
      <Stack direction={'row'}>
        {props.chord?.map((_, index) => (
          <Chord
            key={index}
            chord={_}
          />
        ))}
      </Stack>

      <Stack direction={'row'}>
        <Typography
          sx={{ fontFamily: `'Enriqueta', sans-serif !important`, fontSize: `${fontSize}em` }}
        >
          {props.text}
        </Typography>
        {withDashOverride && <Dash />}
      </Stack>
    </Stack>
  )
}

function Dash() {
  return (
    <Box
      sx={{
        height: '1px',
        display: 'flex',
        borderBottom: '1px solid #888',
        flexGrow: 1,
        alignSelf: 'center'
      }}
    />
  )
}

const Tag: React.FC<TagProps> = ({ tag, value }) => {
  return (
    <div className='tag'>
      {tag}: {value}
    </div>
  )
}

export { Chord, Text, Tag, TextWithChord }
