import React, { FC } from 'react'
import {
  Box,
  CardActionArea,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import SlideshowIcon from '@mui/icons-material/Slideshow'
import QueueMusicIcon from '@mui/icons-material/QueueMusic'
import { useNavigate } from 'react-router'
import { appUrls } from '../helpers/urls'

interface Props {
  playlistId: string
  name: string
  startPlaylist: () => void
}

const PlaylistItem: FC<Props> = (props) => {
  const navigate = useNavigate()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Card sx={{ display: 'flex' }}>
      <CardActionArea
        onClick={props.startPlaylist}
        sx={{ display: 'flex', justifyContent: 'start', pl: 3 }}
      >
        <QueueMusicIcon />
        <CardContent>
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Stack direction={'column'}>
              <Typography>{props.name}</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>

      {!isSmallScreen && (
        <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
          <IconButton
            onClick={() =>
              navigate(appUrls.playlistPresent.withParams({ playlistId: props.playlistId }))
            }
          >
            <SlideshowIcon />
          </IconButton>
        </Box>
      )}
    </Card>
  )
}
export default PlaylistItem
