import React, { FC } from 'react'
import { Stack, Typography } from '@mui/material'
import { useSongContext } from '../../context/SongContext'

interface SongDirectiveProps {
  line: string
  isPreviousLineEmpty: boolean
}

export const SongDirective: FC<SongDirectiveProps> = ({ line, isPreviousLineEmpty }) => {
  const { isDirectivesVisible } = useSongContext()

  if (!isDirectivesVisible) {
    return null
  }

  const tagMatch = line.match(/{(.*?):\s*(.*?)}/)
  if (tagMatch) {
    // const tag = tagMatch[1]
    const value = tagMatch[2]

    return (
      <Stack
        direction={'column'}
        alignItems={'center'}
      >
        {isPreviousLineEmpty && <br />}
        <Typography variant={'h6'}>{value}</Typography>
      </Stack>
    )
  }

  return null
}
