import React, { FC, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import {
  DialogTitle,
  FormControl,
  List,
  ListItem,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import Box from '@mui/material/Box'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { appUrls } from '../../../helpers/urls'
import IPlaylist, { PlaylistArtist } from '../../../models/playlist'
import { useEditPlaylistMutation } from '../../../data/queryFunctions'

export interface Props {
  open: boolean
  onClose: () => void
  playlist: IPlaylist
}

const colors = ['#16a085', '#2980b9', '#8e44ad', '#2c3e50', '#f39c12', '#c0392b']

const PlaylistArtistsDialog: FC<Props> = (props) => {
  const navigate = useNavigate()
  const {
    state: { user }
  } = useAuthContext()

  const { mutate: editPlaylist, isLoading: isEditingPlaylist } = useEditPlaylistMutation()
  const [artistName, setArtistName] = useState<string>('')
  const [artistShortName, setArtistShortName] = useState<string>('')
  const [color, setColor] = useState<string>(colors[0])

  if (!user) {
    navigate(appUrls.login)
  }

  const { enqueueSnackbar } = useSnackbar()

  const addArtistToPlaylist = async (playlist: IPlaylist) => {
    if (!artistName) {
      return
    }

    const artist: PlaylistArtist = {
      name: artistName,
      shortName: artistShortName,
      color
    }

    const playlistToEdit = { ...playlist }

    if (!playlistToEdit.artists) {
      playlistToEdit.artists = []
    }

    playlistToEdit.artists.push({
      name: artist.name,
      shortName: artist.shortName,
      color: artist.color
    })

    await editPlaylist(playlistToEdit, {
      onSuccess: () => {
        enqueueSnackbar(`Umelec ${artist.name} bol pridaný do playlistu ${playlistToEdit.name}`, {
          variant: 'success'
        })
      },
      onError: () => {
        enqueueSnackbar(
          `Umelca ${artist.name} sa nepodarilo pridať do playlistu ${playlistToEdit.name}`,
          {
            variant: 'error'
          }
        )
      }
    })

    props.onClose()
  }

  const handleClose = () => {
    setArtistName('')
    setArtistShortName('')
    setColor(colors[0])
    props.onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle>Pridať umelca do playlistu</DialogTitle>
      <List>
        <ListItem
          id='inputText'
          sx={{ mt: 2 }}
        >
          <FormControl
            fullWidth
            size={'small'}
            variant='standard'
          >
            <OutlinedInput
              id='results'
              placeholder={'Meno umelca'}
              value={artistName ?? ''}
              onChange={(_) => setArtistName(_.target.value)}
              label='Meno umelca'
            />
          </FormControl>
        </ListItem>
        <ListItem
          id='inputText'
          sx={{ mt: 2 }}
        >
          <FormControl
            fullWidth
            size={'small'}
            variant='standard'
          >
            <OutlinedInput
              id='results'
              placeholder={'Skratka mena umelca'}
              value={artistShortName ?? ''}
              onChange={(_) => setArtistShortName(_.target.value)}
              label='Skratka mena umelca'
            />
          </FormControl>
        </ListItem>
        <ListItem
          id='inputText'
          sx={{ mt: 2 }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
          >
            {colors.map((_) => (
              <Box
                key={_}
                onClick={() => setColor(_)}
                sx={{
                  display: 'flex',
                  width: '20px',
                  height: '20px',
                  borderRadius: '12px',
                  background: _,
                  border: `2px solid ${color === _ ? 'black' : 'white'}`,
                  cursor: 'pointer',
                  '&:hover': {
                    border: '2px solid grey'
                  }
                }}
              />
            ))}
          </Stack>
        </ListItem>
        <ListItem>
          <LoadingButton
            loading={isEditingPlaylist}
            variant={'contained'}
            onClick={() => addArtistToPlaylist(props.playlist)}
          >
            <Typography>Uložiť</Typography>
          </LoadingButton>
        </ListItem>
      </List>
    </Dialog>
  )
}

export default PlaylistArtistsDialog
