import React, { FC, useEffect, useState } from 'react'
import {
  Alert,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Typography
} from '@mui/material'
import { useParams } from 'react-router'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ReactDOMServer from 'react-dom/server'
import Box from '@mui/material/Box'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import Layout from '../components/layouts/Layout'
import { useFirebasePlaylistById } from '../data/queryFunctions'
import { songIdToString } from '../helpers/utils'
import { ISong } from '../models/song'
import SongPresentSettings from '../components/song/SongPresentSettings'
import Slideshow from '../components/song/Slideshow'
import { useSlideshowContext } from '../context/SlideshowContext'
import AddSongToPlaylist from '../components/dialogs/AddSongToPlaylist'

export type PlaylistPresentPageParams = {
  playlistId: string
}

const PlaylistPresentPage: FC = () => {
  const params = useParams<PlaylistPresentPageParams>()
  const playlistId = params.playlistId as string

  const { data: playlist } = useFirebasePlaylistById(playlistId)
  const { state: slideshowState, dispatch: slideshowDispatch } = useSlideshowContext()

  const [currentSong, setCurrentSong] = useState<ISong>()

  const [currentText, setCurrentText] = useState<string[]>([])
  const [fontSizeEm, setFontSizeEm] = useState<number>(5)
  const [isTextVisible, setIsTextVisible] = useState<boolean>(true)
  const [openSearch, setOpenSearch] = useState(false)
  // const [lineHeight, setLineHeight] = useState<number>(1.5)

  useEffect(() => {
    slideshowDispatch((_) => (_ ? { ..._, playlistId } : undefined))
  }, [playlistId, slideshowDispatch])

  const showSlide = (showText: string[], showFontSizeEm: number) => {
    let windowRef: Window | null = slideshowState?.currentWindow ?? null
    if (!windowRef || windowRef.closed) {
      windowRef = window.open(
        '',
        '_blank',
        'width=800,height=600,menubar=no,toolbar=no,location=no'
      )
    }

    if (windowRef) {
      const html = ReactDOMServer.renderToString(
        <Slideshow
          text={showText}
          fontSizeEm={showFontSizeEm}
        />
      )
      windowRef.document.open()
      windowRef.document.write(html)
      windowRef.document.close()
    }

    slideshowDispatch({ playlistId, currentWindow: windowRef })
  }

  const handleFontSizeChange = (increment: number) => {
    const newFontSize = fontSizeEm + increment

    if (currentText.length > 0) {
      showSlide(currentText, newFontSize)
    }
    setFontSizeEm(newFontSize)
  }

  const onDialogClose = () => {
    setOpenSearch(false)
  }

  return (
    <Layout>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          <Stack
            direction={'column'}
            alignItems={'center'}
            gap={2}
          >
            <Typography variant={'h5'}>Zoznam piesní</Typography>

            <Alert
              severity={'info'}
              action={
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  size={'small'}
                  onClick={() => setOpenSearch(true)}
                  sx={{ ml: 1 }}
                >
                  Pridať pieseň
                </Button>
              }
              sx={{ width: '100%' }}
            >
              Hrajú niečo mimo playlistu?
            </Alert>

            <List sx={{ width: '100%' }}>
              {(playlist?.songs ?? []).map((_) => (
                <ListItem
                  key={songIdToString(_.id)}
                  disablePadding
                >
                  <ListItemButton onClick={() => setCurrentSong(_)}>
                    <ListItemIcon>
                      <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={_.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
        >
          <Stack
            alignItems={'center'}
            direction={'column'}
            gap={2}
          >
            <Typography variant={'h5'}>Aktuálna pieseň</Typography>
            {currentSong && (
              <SongPresentSettings
                song={currentSong}
                onSlideClick={(_) => {
                  showSlide(isTextVisible ? _ : [], fontSizeEm)
                  setCurrentText(_)
                }}
              />
            )}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
        >
          <Stack
            direction={'column'}
            alignItems={'center'}
            gap={2}
          >
            <Typography variant={'h5'}>Aktuálny slajd</Typography>
            <Paper sx={{ px: 3, py: 2 }}>
              <Stack
                direction={'column'}
                gap={2}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={isTextVisible}
                      onChange={(_) => {
                        const newValue = _.target.checked
                        if (newValue) {
                          showSlide(currentText, fontSizeEm)
                        } else {
                          showSlide([], fontSizeEm)
                        }
                        setIsTextVisible(newValue)
                      }}
                    />
                  }
                  label={'Zobraziť text na slajde'}
                />

                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  direction={'row'}
                  gap={1}
                >
                  <Typography>
                    Veľkosť písma <i>(prejaví sa len v okne s textom)</i>:
                  </Typography>

                  <ButtonGroup
                    variant={'text'}
                    color={'secondary'}
                    size={'small'}
                  >
                    <Button onClick={() => handleFontSizeChange(-0.2)}>
                      <RemoveIcon />
                    </Button>
                    <Button disabled>{fontSizeEm.toFixed(1)}</Button>
                    <Button onClick={() => handleFontSizeChange(0.2)}>
                      <AddIcon />
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Stack>
            </Paper>

            {currentText.length > 0 && (
              <Box
                sx={{
                  textAlign: 'center',
                  background: 'black',
                  color: 'white',
                  p: 4,
                  boxSizing: 'border-box',
                  width: '100%',
                  borderRadius: 1
                }}
              >
                {currentText.map((_, index) => (
                  <>
                    <span
                      key={index}
                      style={{ fontFamily: `'Enriqueta'` }}
                    >
                      {_}
                    </span>
                    <br />
                  </>
                ))}
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>

      {playlist && (
        <AddSongToPlaylist
          playlist={playlist}
          open={openSearch}
          onClose={onDialogClose}
        />
      )}
    </Layout>
  )
}

export default PlaylistPresentPage
