import React, { FC, useState } from 'react'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import IconButton from '@mui/material/IconButton'
import ManagePlaylistsDialog from '../dialogs/ManagePlaylistsDialog'
import SongListItem from '../SongListItem'
import { ISong } from '../../models/song'

interface Props {
  song: ISong
}

const SongListItemWithAddToPlaylistDialog: FC<Props> = (props) => {
  const [open, setOpen] = useState(false)

  const onDialogClose = () => {
    setOpen(false)
  }

  return (
    <>
      <SongListItem
        song={props.song}
        buttons={
          <IconButton onClick={() => setOpen(true)}>
            <PlaylistAddIcon />
          </IconButton>
        }
      />

      <ManagePlaylistsDialog
        song={props.song}
        open={open}
        onClose={onDialogClose}
      />
    </>
  )
}

export default SongListItemWithAddToPlaylistDialog
