import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router'
import { CircularProgress } from '@mui/material'
import Layout from '../components/layouts/Layout'
import Song from '../components/Song'
import { use4334SongById } from '../data/queryFunctions'
import { removeChordProTag } from '../helpers/utils'

export type SongPageParams = {
  songId: string
}

const Song4334Page: FC = () => {
  const params = useParams<SongPageParams>()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: song, isLoading } = use4334SongById(params.songId!)

  const modifiedSong = useMemo(
    () => (song ? { ...song, chordProText: removeChordProTag(song.chordProText) } : undefined),
    [song]
  )

  return (
    <Layout>
      {isLoading && <CircularProgress />}
      {modifiedSong && (
        <Song
          song={modifiedSong}
          showAddToPlaylist
        />
      )}
    </Layout>
  )
}
export default Song4334Page
