import { useLocalStorage } from 'usehooks-ts'

export const localStorageKeys = {
  latestPlaylists: 'latestPlaylists',
  darkTheme: 'darkTheme'
}

export interface LatestPlaylists {
  id: string
  name: string
}

const maxLatestPlaylists = 3

export const useLatestPlaylists = () => {
  const [latestPlaylists, setLatestPlaylists] = useLocalStorage<LatestPlaylists[]>(
    localStorageKeys.latestPlaylists,
    []
  )

  const addLatestPlaylist = (id: string, name: string) => {
    const newLatestPlaylists = [
      { id, name },
      ...latestPlaylists.filter((playlist) => playlist.id !== id).slice(0, maxLatestPlaylists - 1)
    ]
    setLatestPlaylists(newLatestPlaylists)
  }

  return { latestPlaylists, addLatestPlaylist }
}
