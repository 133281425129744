import React, { FC } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import YouTube from 'react-youtube'
import { ISong } from '../models/song'
import { appUrls } from '../helpers/urls'
import { useDeleteOwnSongMutation } from '../data/queryFunctions'
import { SongToolbar } from './song/SongToolbar'
import SongChordPro from './SongChordPro'
import { SongTransposeProvider, useSongTransposeContext } from '../context/SongTransposeContext'
import { getYoutubeVideoId } from '../helpers/utils'
import { IPlaylist, ISongInPlaylistFirestore, PlaylistArtist } from '../models/playlist'
import { NavButtonChord } from './SongInPlaylist'
import { transposeChord } from '../helpers/chords'

const SongTransposeInfo: FC<{ song: ISong }> = (props) => {
  const { transposeHowMuch, capo } = useSongTransposeContext()

  return (
    <NavButtonChord sx={{ fontSize: '1em' }}>
      {transposeChord(props.song.chordKey, transposeHowMuch ?? 0)}{' '}
      {capo !== 0 && (
        <>
          {capo > 0 && '+'}
          {capo}
        </>
      )}
    </NavButtonChord>
  )
}

interface Props {
  song: ISong
  showAddToPlaylist: boolean
  songInPlaylist?: ISongInPlaylistFirestore
  onSongInPlaylistSave?: (songInPlaylist: Omit<ISongInPlaylistFirestore, 'id'>) => void
  playlist?: IPlaylist
  artist?: PlaylistArtist
}

const Song: FC<Props> = (props) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { mutate: deleteSong } = useDeleteOwnSongMutation()

  const onDeleteClick = async () => {
    await deleteSong(props.song.id.id, {
      onSuccess: () => {
        enqueueSnackbar(`Pieseň ${props.song.title} bola úspešne zmazaná`, { variant: 'success' })
        navigate(appUrls.home)
      },
      onError: () => {
        enqueueSnackbar(`Nastala chyba pri mazaní piesne ${props.song.title}`, { variant: 'error' })
      }
    })
  }

  const isInPlaylist = props.songInPlaylist !== undefined
  const youtubeVideo = getYoutubeVideoId(props.song.youtubeUrl ?? '')

  return (
    <SongTransposeProvider>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={isInPlaylist || !youtubeVideo ? 12 : 8}
          sx={{ textAlign: 'center' }}
        >
          <Typography
            variant={'h4'}
            sx={{ mb: 2 }}
          >
            {props.song.title} {isInPlaylist && <SongTransposeInfo song={props.song} />}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <SongToolbar
              song={props.song}
              showAddToPlaylist={props.showAddToPlaylist}
              onEditClick={() =>
                navigate(appUrls.songOwnEdit.withParams({ songId: props.song.id.id }))
              }
              onDeleteClick={onDeleteClick}
              songInPlaylist={props.songInPlaylist}
              onSongInPlaylistSave={props.onSongInPlaylistSave}
              playlist={props.playlist}
              artist={props.artist}
            />
          </Box>

          <SongChordPro chordProText={props.song.chordProText} />
        </Grid>

        {!isInPlaylist && youtubeVideo && (
          <Grid
            item
            xs={12}
            md={4}
            sx={{ background: '#efefef', my: 2, borderRadius: 2 }}
          >
            <Box sx={{ p: 2 }}>
              <style>
                {`
                  .youtube-iframe {
                    width: 100%;
                  }
                `}
              </style>
              <YouTube
                videoId={youtubeVideo}
                iframeClassName={'youtube-iframe'}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </SongTransposeProvider>
  )
}

export default Song
