import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import {
  DialogContent,
  DialogTitle,
  FormControl,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material'
import Button from '@mui/material/Button'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import IconButton from '@mui/material/IconButton'
import QRCode from 'react-qr-code'

export interface Props {
  url: string
  open: boolean
  onClose: () => void
}

const SharePlaylistDialog: FC<Props> = (props) => {
  const handleClose = () => {
    props.onClose()
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.url)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle>Zdieľať playlist</DialogTitle>
      <DialogContent>
        <Stack
          direction={'column'}
          spacing={2}
        >
          <QRCode value={props.url} />

          <Typography variant={'body1'}>
            Alebo zdieľaj tento playlist pomocou tohto odkazu:
          </Typography>
          <Stack
            direction={'row'}
            spacing={1}
          >
            <FormControl fullWidth>
              <OutlinedInput
                value={props.url}
                readOnly
                fullWidth
                multiline
              />
            </FormControl>

            <IconButton onClick={copyToClipboard}>
              <ContentCopyIcon />
            </IconButton>
          </Stack>

          <Button
            variant={'contained'}
            color={'primary'}
            fullWidth
            onClick={handleClose}
            sx={{ mt: 1 }}
          >
            Zavrieť
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
export default SharePlaylistDialog
