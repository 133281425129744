import { useEffect, useState } from 'react'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'
import { LOGIN } from '../context/AuthContext'

export const useSignup = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>(null)
  const [isPending, setIsPending] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = async (
    email: string,
    password: string,
    displayName: string | null | undefined
  ) => {
    setError(null)
    setIsPending(true)

    try {
      // signup user
      const res = await createUserWithEmailAndPassword(projectAuth, email, password)

      if (!res || !res.user) {
        throw new Error('Could not complete signup')
      }

      // add display name to user
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await updateProfile(res.user!, { displayName })

      // dispatch login action
      dispatch({ type: LOGIN, value: res.user })

      // update state
      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}
