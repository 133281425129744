import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  get4334SongById,
  get4334SongByIds,
  get4334SongsByText,
  getFirebasePlaylistById,
  getFirebasePlaylistsOfUser,
  getOwnSongById
} from './apiFunctions'
import IPlaylist from '../models/playlist'
import {
  addPlaylistToFirestore,
  addSlideshowToFirestore,
  addSongToFirestore,
  deletePlaylistFromFirestore,
  deleteSongFromFirestore,
  editPlaylistInFirestore,
  editSlideshowInFirestore,
  editSongInFirestore
} from './firestoreFunctions'
import { queryKeys } from './queryKeys'
import { useAuthContext } from '../hooks/useAuthContext'
import { ISong } from '../models/song'
import { ISlideshow } from '../models/slideshow'

export const use4334SongsSearch = (text: string | undefined, page: number) =>
  useQuery({
    queryKey: queryKeys.songs4334ByText(text, page),
    queryFn: () => get4334SongsByText(text, page),
    staleTime: 1000 * 60 * 60 // 1 hour
  })

export const use4334SongById = (id: string) =>
  useQuery({
    queryKey: queryKeys.song4334(id),
    queryFn: () => get4334SongById(id),
    staleTime: 1000 * 60 * 60 // 1 hour
  })

export const use4334SongByIds = (ids: string[]) =>
  useQuery({
    enabled: ids.length !== 0,
    queryKey: queryKeys.songs4334ByIds(ids),
    queryFn: () => get4334SongByIds(ids)
  })

export const useFirebasePlaylistsOfUser = (userId: string) =>
  useQuery({
    queryKey: queryKeys.firebasePlaylistsOfUser(userId),
    queryFn: () => getFirebasePlaylistsOfUser(userId)
  })

export const useFirebasePlaylistById = (id: string) =>
  useQuery({
    queryKey: queryKeys.firebasePlaylist(id),
    queryFn: () => getFirebasePlaylistById(id)
  })

export const useCreateOwnSongMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<ISong, void, Omit<ISong, 'id'>, unknown>({
    mutationFn: (song) => addSongToFirestore(song),
    onSuccess: async (newSong) => {
      queryClient.setQueryData<ISong[]>(queryKeys.ownSongsByText(undefined), (oldData) => [
        ...(oldData ?? []),
        newSong
      ])
    }
  })
}

export const useEditOwnSongMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<ISong, void, ISong, unknown>({
    mutationFn: (song) => editSongInFirestore(song),
    onSuccess: async (editedSong) => {
      await queryClient.setQueryData<ISong>(queryKeys.firebaseSong(editedSong.id.id), editedSong)
    }
  })
}

export const useDeleteOwnSongMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<string, void, string, unknown>({
    mutationFn: (id) => deleteSongFromFirestore(id),
    onSuccess: async (id) => {
      await queryClient.invalidateQueries(queryKeys.ownSongsByText(undefined))
      await queryClient.invalidateQueries(queryKeys.firebaseSong(id))
    }
  })
}

export const useCreatePlaylistMutation = () => {
  const {
    state: { user }
  } = useAuthContext()

  if (!user) {
    throw new Error('User is not logged in')
  }

  const queryClient = useQueryClient()

  return useMutation<IPlaylist, void, Omit<IPlaylist, 'id'>, unknown>({
    mutationFn: (playlist) => addPlaylistToFirestore(playlist),
    onSuccess: async (newPlaylist) => {
      queryClient.setQueryData<IPlaylist[]>(
        queryKeys.firebasePlaylistsOfUser(user.uid),
        (oldData) => [...(oldData ?? []), newPlaylist]
      )
    }
  })
}

export const useEditPlaylistMutation = () => {
  const {
    state: { user }
  } = useAuthContext()

  const queryClient = useQueryClient()

  return useMutation<IPlaylist, void, IPlaylist, unknown>({
    mutationFn: (playlist) => editPlaylistInFirestore(playlist),
    onSuccess: async (newPlaylist) => {
      if (!user) {
        throw new Error('User is not logged in')
      }

      await queryClient.invalidateQueries(queryKeys.firebasePlaylist(newPlaylist.id))

      queryClient.setQueryData<IPlaylist[]>(
        queryKeys.firebasePlaylistsOfUser(user.uid),
        (oldData) => (oldData ?? []).map((_) => (_.id === newPlaylist.id ? newPlaylist : _))
      )
    }
  })
}

export const useDeletePlaylistMutation = () => {
  const {
    state: { user }
  } = useAuthContext()

  const queryClient = useQueryClient()

  return useMutation<string, void, string, unknown>({
    mutationFn: (playlist) => deletePlaylistFromFirestore(playlist),
    onSuccess: async (newPlaylist) => {
      if (!user) {
        throw new Error('User is not logged in')
      }

      queryClient.setQueryData<IPlaylist[]>(
        queryKeys.firebasePlaylistsOfUser(user.uid),
        (oldData) => (oldData ?? []).filter((playlist) => playlist.id !== newPlaylist)
      )
    }
  })
}

export const useFirebaseSongById = (id: string) =>
  useQuery({
    queryKey: queryKeys.firebaseSong(id),
    queryFn: () => getOwnSongById(id)
  })

export const useCreateSlideshowMutation = () => {
  return useMutation<ISlideshow, void, Omit<ISlideshow, 'id'>, unknown>({
    mutationFn: (slideshow) => addSlideshowToFirestore(slideshow)
  })
}

export const useEditSlideshowMutation = () => {
  return useMutation<ISlideshow, void, ISlideshow, unknown>({
    mutationFn: (slideshow) => editSlideshowInFirestore(slideshow)
  })
}
