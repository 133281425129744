import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import Layout from '../components/layouts/Layout'
import { useCreateOwnSongMutation } from '../data/queryFunctions'
import SongForm from '../components/own-songs/SongForm'
import { ISong } from '../models/song'

const NewSongPage: FC = () => {
  const { mutate: addSong } = useCreateOwnSongMutation()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (data: Omit<ISong, 'id'>) => {
    await addSong(data, {
      onSuccess: () => {
        enqueueSnackbar(`Pieseň ${data.title} bola úspešne pridaná`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Nastala chyba pri pridávaní piesne', { variant: 'error' })
      }
    })
  }

  return (
    <Layout>
      <Typography variant={'h6'}>Nová pieseň</Typography>
      <SongForm onSubmit={onSubmit} />
    </Layout>
  )
}

export default NewSongPage
