import { styled } from '@mui/material/styles'
import { Box, BoxProps, Typography } from '@mui/material'
import { common } from '@mui/material/colors'
import { FC } from 'react'

const LogoBox = styled(Box)<BoxProps>(({ theme }) => ({
  color: theme.palette.success.main,
  borderColor: common.white,
  borderWidth: '3px',
  borderStyle: 'solid',
  padding: theme.spacing(0, 1)
}))

export const Logo: FC<BoxProps> = (props) => {
  return (
    <LogoBox {...props}>
      <Typography sx={{ fontSize: 24, color: common.white, letterSpacing: '0.02em' }}>
        4334
      </Typography>
    </LogoBox>
  )
}
