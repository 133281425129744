import React, { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { LoadingButton } from '@mui/lab'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { useSnackbar } from 'notistack'
import SlideshowIcon from '@mui/icons-material/Slideshow'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import ShareIcon from '@mui/icons-material/Share'
import { useAuthContext } from '../hooks/useAuthContext'
import { ISongInPlaylistFirestore, PlaylistArtist } from '../models/playlist'
import { ISong } from '../models/song'
import { appUrls } from '../helpers/urls'
import { delay, songIdToString } from '../helpers/utils'
import {
  useDeletePlaylistMutation,
  useEditPlaylistMutation,
  useFirebasePlaylistById
} from '../data/queryFunctions'
import SongInPlaylist, { NavButtonChord } from '../components/SongInPlaylist'
import Layout from '../components/layouts/Layout'
import SharePlaylistDialog from '../components/dialogs/SharePlaylistDialog'
import { transposeChord } from '../helpers/chords'
import SongArtistAvatar from '../components/song/misc/SongArtistAvatar'

const toUndefinedIfNull = (o: unknown) => (o === null ? undefined : o)

export type PlaylistPageParams = {
  playlistId: string
}

const PlaylistPage: FC = () => {
  const params = useParams<PlaylistPageParams>()
  const navigate = useNavigate()

  const {
    state: { user }
  } = useAuthContext()

  const { enqueueSnackbar } = useSnackbar()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: playlist, isLoading } = useFirebasePlaylistById(params.playlistId!)
  const { mutate: editPlaylist, isLoading: isSavingPlaylist } = useEditPlaylistMutation()
  const { mutate: deletePlaylistMutate, isLoading: isDeleting } = useDeletePlaylistMutation()

  const [currentSongIndex, setCurrentSongIndex] = useState<number>(0)

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [showShareDialog, setShowShareDialog] = useState<boolean>(false)

  const onSongClicked = async (index: number) => {
    setCurrentSongIndex(index)

    await delay(200)

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const onPlaylistPresentClick = async (id: string) => {
    navigate(appUrls.playlistPresent.withParams({ playlistId: id }))
  }

  const onSharePlaylist = () => {
    setShowShareDialog(true)
  }

  const deletePlaylist = async () => {
    if (!playlist) {
      return
    }

    await deletePlaylistMutate(playlist.id)
    navigate(appUrls.playlists)
  }

  const saveNewSongsToPlaylist = async (
    songs: ISongInPlaylistFirestore[],
    successMessage: string,
    errorMessage: string
  ) => {
    if (!playlist) {
      return
    }

    await editPlaylist(
      {
        ...playlist,
        songs
      },
      {
        onSuccess: () => {
          setCurrentSongIndex((_) => (_ >= songs.length ? songs.length - 1 : _))

          enqueueSnackbar(successMessage, {
            variant: 'success'
          })
        },
        onError: () => {
          enqueueSnackbar(errorMessage, {
            variant: 'error'
          })
        }
      }
    )
  }

  const deleteSongFromPlaylist = async (song: ISong, index: number) => {
    if (!playlist) {
      return
    }

    const newSongs: ISongInPlaylistFirestore[] = playlist.songs
      .filter((_, i) => i !== index)
      .map((_) => ({ id: _.id, transpose: _.transpose, capo: _.capo, artist: _.artist }))

    await saveNewSongsToPlaylist(
      newSongs,
      `Pieseň ${song.title} bola odstránená z playlistu`,
      `Pieseň ${song.title} sa nepodarilo odstrániť z playlistu`
    )
  }

  const onSongInPlaylistTransposeChange = async (
    song: ISong,
    songInPlaylist: Omit<ISongInPlaylistFirestore, 'id'>
  ) => {
    if (!playlist) {
      return
    }

    const newSongs: ISongInPlaylistFirestore[] = playlist.songs.map((_) => {
      if (songIdToString(_.id) === songIdToString(song.id)) {
        return {
          id: _.id,
          ...songInPlaylist
        }
      }

      return _
    })

    await saveNewSongsToPlaylist(
      newSongs,
      `Pieseň ${song.title} bola upravená`,
      `Pieseň ${song.title} sa nepodarilo upraviť`
    )
  }

  const droppableId = 'playlist-song-list'
  const onDragEnd = async (result: DropResult) => {
    if (!playlist) {
      return
    }

    const { destination, source } = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const newArray = [...playlist.songs]
    newArray.splice(source.index, 1)
    newArray.splice(destination.index, 0, playlist.songs[source.index])

    await saveNewSongsToPlaylist(
      newArray,
      `Poradie piesní v playliste bolo zmenené`,
      `Poradie piesní v playliste sa nepodarilo zmeniť`
    )
  }

  const previousSongIndex = playlist && currentSongIndex - 1 < 0 ? undefined : currentSongIndex - 1
  const nextSongIndex =
    playlist && currentSongIndex + 1 >= (playlist?.songs.length ?? 0)
      ? undefined
      : currentSongIndex + 1

  const previousSongProps =
    previousSongIndex !== undefined
      ? {
          go: () => setCurrentSongIndex(previousSongIndex),
          song: (playlist?.songs ?? [])[previousSongIndex],
          transpose: (playlist?.songs ?? [])[previousSongIndex]?.transpose,
          capo: (playlist?.songs ?? [])[previousSongIndex]?.capo,
          artist: toUndefinedIfNull((playlist?.songs ?? [])[previousSongIndex]?.artist) as
            | PlaylistArtist
            | undefined
        }
      : undefined

  const nextSongProps =
    nextSongIndex !== undefined
      ? {
          go: () => setCurrentSongIndex(nextSongIndex),
          song: (playlist?.songs ?? [])[nextSongIndex],
          transpose: (playlist?.songs ?? [])[nextSongIndex]?.transpose,
          capo: (playlist?.songs ?? [])[nextSongIndex]?.capo,
          artist: toUndefinedIfNull((playlist?.songs ?? [])[nextSongIndex]?.artist) as
            | PlaylistArtist
            | undefined
        }
      : undefined

  const areSomeSongs = playlist && playlist.songs.length !== 0
  const isEditingDisabled = !isEditing || isSavingPlaylist || isDeleting
  const showProgress = isLoading || isSavingPlaylist || isDeleting

  const shareUrl = `https://4334.domov.church${appUrls.playlist.withParams({
    playlistId: params.playlistId ?? ''
  })}`

  return (
    <Layout>
      <Grid container>
        <Grid
          item
          xs={12}
          md={8}
        >
          <SharePlaylistDialog
            url={shareUrl}
            open={showShareDialog}
            onClose={() => setShowShareDialog(false)}
          />
          {isLoading && <CircularProgress />}
          {areSomeSongs && (
            <SongInPlaylist
              playlist={playlist}
              song={playlist.songs[currentSongIndex]}
              previousSong={previousSongProps}
              nextSong={nextSongProps}
              onSongInPlaylistSave={(_) =>
                onSongInPlaylistTransposeChange(playlist.songs[currentSongIndex], _)
              }
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
        >
          <Stack>
            <List>
              {playlist && (
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  gap={1}
                  sx={{ mb: 2 }}
                >
                  <Typography
                    variant={'h6'}
                    sx={{ flexGrow: 1 }}
                  >
                    {playlist.name}
                  </Typography>

                  <Tooltip title={'Prezentovať'}>
                    <IconButton
                      onClick={() => onPlaylistPresentClick(playlist.id)}
                      size={'small'}
                    >
                      <SlideshowIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={'Zdieľať'}>
                    <IconButton
                      onClick={onSharePlaylist}
                      size={'small'}
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>

                  {user && (
                    <Tooltip title={'Povoliť úpravy'}>
                      <IconButton
                        onClick={() => setIsEditing((_) => !_)}
                        size={'small'}
                      >
                        {isEditing ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              )}

              <Box sx={{ mb: 2 }}>
                {showProgress ? (
                  <LinearProgress color={'secondary'} />
                ) : (
                  <Divider sx={{ height: 3 }} />
                )}
              </Box>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={droppableId}>
                  {(provided) => (
                    <div
                      key={droppableId}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {(playlist?.songs ?? []).map((_, index) => (
                        <Draggable
                          key={songIdToString(_.id)}
                          draggableId={songIdToString(_.id)}
                          index={index}
                          isDragDisabled={isEditingDisabled}
                        >
                          {(providedInner) => (
                            <ListItem
                              {...providedInner.draggableProps}
                              {...providedInner.dragHandleProps}
                              ref={providedInner.innerRef}
                              disablePadding
                            >
                              <ListItemButton
                                onClick={() => onSongClicked(index)}
                                disabled={isDeleting}
                              >
                                <ListItemIcon>
                                  <ChevronRightIcon />
                                </ListItemIcon>
                                {_.artist && (
                                  <SongArtistAvatar
                                    artist={_.artist}
                                    size={'small'}
                                    sx={{ mr: 1, ml: -1 }}
                                  />
                                )}
                                <ListItemText primary={_.title} />
                                <NavButtonChord sx={{ fontSize: '1em' }}>
                                  {transposeChord(_.chordKey, _.transpose ?? 0)}
                                  {_.capo !== 0 && (
                                    <>
                                      {_.capo > 0 && '+'}
                                      {_.capo}
                                    </>
                                  )}
                                </NavButtonChord>
                              </ListItemButton>

                              {isEditing && (
                                <Stack
                                  direction={'row'}
                                  alignItems={'center'}
                                >
                                  <Tooltip title={'Potiahnutím zmeň poradie'}>
                                    <DragIndicatorIcon sx={{ mx: 1, opacity: 0.5 }} />
                                  </Tooltip>

                                  <Tooltip title={'Odstrániť pieseň z playlistu'}>
                                    <IconButton
                                      onClick={() => deleteSongFromPlaylist(_, index)}
                                      disabled={isEditingDisabled}
                                    >
                                      <DeleteIcon color={'error'} />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              )}
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </List>

            <Divider
              light
              sx={{ my: 2 }}
            />

            {isEditing && (
              <LoadingButton
                startIcon={<DeleteIcon />}
                loading={isDeleting}
                onClick={deletePlaylist}
                color={'error'}
                sx={{ alignSelf: 'flex-end' }}
              >
                Odstrániť playlist
              </LoadingButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default PlaylistPage
