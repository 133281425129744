export const queryKeys = {
  song4334: (id: string) => ['4334-song', id],
  songs4334ByText: (text: string | undefined, page: number) => ['4334-songs-search', text, page],
  songs4334ByIds: (ids: string[]) => ['4334-songs', ...ids],

  ownSongsByText: (text: string | undefined) => ['own-songs', text],

  firebasePlaylistsOfUser: (userId: string) => ['firebase-playlists-of-user', userId],
  firebasePlaylist: (id: string) => ['firebase-playlist', id],
  firebaseSong: (id: string) => ['firebase-song', id]
}
