import React, { FC } from 'react'
import { useParams } from 'react-router'
import { CircularProgress } from '@mui/material'
import Layout from '../components/layouts/Layout'
import Song from '../components/Song'
import { useFirebaseSongById } from '../data/queryFunctions'

export type SongPageParams = {
  songId: string
}

const SongOwnPage: FC = () => {
  const params = useParams<SongPageParams>()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: song, isLoading } = useFirebaseSongById(params.songId!)

  return (
    <Layout>
      {isLoading && <CircularProgress />}
      {song && (
        <Song
          song={song}
          showAddToPlaylist
        />
      )}
    </Layout>
  )
}
export default SongOwnPage
