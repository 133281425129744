import React, { FC } from 'react'
import { List, ListItemButton, Stack, Typography } from '@mui/material'
import { ISong } from '../../models/song'
import { createSlidesFromText } from '../../helpers/utils'

interface Props {
  song: ISong
  onSlideClick: (text: string[]) => void
}

const SongPresentSettings: FC<Props> = (props) => {
  const slides = createSlidesFromText(props.song.chordProText)

  return (
    <Stack
      direction={'column'}
      gap={2}
    >
      <List>
        {slides.map((_, index1) => (
          <ListItemButton
            key={index1}
            onClick={() => props.onSlideClick(_.text)}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            {_.text.map((line, index2) => (
              <Typography key={`${index1}|${index2}`}>{line}</Typography>
            ))}
          </ListItemButton>
        ))}
      </List>
    </Stack>
  )
}

export default SongPresentSettings
