import React, { FC, useState } from 'react'
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  Stack,
  Switch,
  TextField
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Controller, useForm, useWatch } from 'react-hook-form'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import SongChordPro from '../SongChordPro'
import { ISong } from '../../models/song'
import { SongTransposeProvider } from '../../context/SongTransposeContext'
import { SongToolbar } from '../song/SongToolbar'
import { chordsMajor, isChordMajor } from '../../helpers/chords'

type FormData = {
  title: string
  author: string
  album?: string
  chordProText: string
  chordKey: string
  youtubeUrl?: string
  spotifyUrl?: string
}

interface Props {
  onSubmit: (data: FormData) => void
  song?: ISong
}

const SongForm: FC<Props> = (props) => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FormData>({ mode: 'onBlur', defaultValues: props.song ?? {} })

  const chordProText = useWatch({
    control,
    name: 'chordProText',
    defaultValue: props.song?.chordProText ?? ''
  })

  const chordKey = useWatch({
    control,
    name: 'chordKey',
    defaultValue: props.song?.chordKey ?? ''
  })

  const dummySong: ISong = {
    id: { id: '1', source: '4334' },
    title: '',
    author: '',
    chordProText: '',
    chordKey: ''
  }

  const [isChordKeyMajor, setIsChordKeyMajor] = useState(
    props.song?.chordKey ? isChordMajor(props.song.chordKey) : true
  )

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={6}
      >
        <form onSubmit={handleSubmit(props.onSubmit)}>
          <Stack gap={2}>
            <TextField
              label='Názov'
              {...register('title', { required: true })}
              error={!!errors.title}
              helperText={errors.title && 'Toto pole je povinné'}
            />

            <TextField
              label='Autor'
              {...register('author', { required: true })}
              error={!!errors.author}
              helperText={errors.author && 'Toto pole je povinné'}
            />

            <TextField
              label='Album'
              {...register('album')}
            />

            <TextField
              label='Text a akordy'
              multiline
              rows={25}
              {...register('chordProText', { required: true })}
              error={!!errors.chordProText}
              helperText={errors.chordProText && 'Toto pole je povinné'}
            />

            <Stack
              direction={'row'}
              spacing={2}
            >
              <FormControl
                fullWidth
                error={Boolean(errors.chordKey)}
              >
                <InputLabel id='chordKey-label'>Tónina</InputLabel>
                <Controller
                  name='chordKey'
                  control={control}
                  rules={{ required: 'Prosím, zvoľte tóninu' }}
                  render={({ field }) => (
                    <Select
                      labelId='chordKey-label'
                      label='Tónina'
                      {...field}
                    >
                      {chordsMajor
                        .map((_) => (isChordKeyMajor ? _ : _.toLowerCase()))
                        .map((chord) => (
                          <MenuItem
                            key={chord}
                            value={chord}
                          >
                            {chord}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                {errors.chordKey && <FormHelperText>{errors.chordKey.message}</FormHelperText>}
              </FormControl>

              <FormControlLabel
                control={
                  <Switch
                    checked={isChordKeyMajor}
                    onChange={(_) => {
                      setValue(
                        'chordKey',
                        _.target.checked ? chordKey.toUpperCase() : chordKey.toLowerCase()
                      )
                      setIsChordKeyMajor(_.target.checked)
                    }}
                  />
                }
                label={'Dur'}
              />
            </Stack>

            <TextField
              label='YouTube URL'
              {...register('youtubeUrl')}
            />

            <TextField
              label='Spotify URL'
              {...register('spotifyUrl')}
            />

            <LoadingButton
              type='submit'
              variant='contained'
              loading={isSubmitting}
              sx={{ mt: 2 }}
            >
              Uložiť
            </LoadingButton>
          </Stack>
        </form>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <SongTransposeProvider>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SongToolbar
              song={dummySong}
              showAddToPlaylist={false}
              onEditClick={() => {}}
              onDeleteClick={() => {}}
            />
          </Box>

          <Divider sx={{ my: 2, visibility: 'hidden' }} />

          <SongChordPro chordProText={chordProText} />
        </SongTransposeProvider>
      </Grid>
    </Grid>
  )
}

export default SongForm
