import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react'
import { useInterval } from 'usehooks-ts'

interface SlideshowState {
  playlistId: string
  currentWindow: Window | null
}

export const SlideshowContext = createContext<{
  state: SlideshowState | undefined
  dispatch: Dispatch<SetStateAction<SlideshowState | undefined>>
}>({
  state: undefined,
  dispatch: () => {}
})

export const SlideshowContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<SlideshowState>()

  useInterval(
    () => {
      if (state?.currentWindow?.closed) {
        setState((_) => (_ ? { ..._, currentWindow: null } : undefined))
      }
    },
    // Delay in milliseconds or null to stop it
    state?.currentWindow ? 1000 : null
  )

  const contextValue = useMemo(() => ({ state, dispatch: setState }), [state])

  return <SlideshowContext.Provider value={contextValue}>{children}</SlideshowContext.Provider>
}

export const useSlideshowContext = () => useContext(SlideshowContext)
