import React, { FC } from 'react'
import { Box, CardActionArea, Stack, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useNavigate } from 'react-router'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import { ISong } from '../models/song'
import { getUrlForSong } from '../helpers/urls'

export interface Props {
  song: ISong
  buttons?: React.ReactNode
  customOnClick?: () => void
}

const SongListItem: FC<Props> = (props) => {
  const navigate = useNavigate()

  const onClick = () => {
    if (props.customOnClick) {
      props.customOnClick()

      return
    }

    navigate(getUrlForSong(props.song.id))
  }

  return (
    <Card sx={{ display: 'flex', height: '100%' }}>
      <CardActionArea
        onClick={onClick}
        sx={{ display: 'flex', justifyContent: 'start', pl: 3 }}
      >
        <MusicNoteIcon />
        <CardContent>
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Stack direction={'column'}>
              <Typography>{props.song.title}</Typography>
              {props.song.author && (
                <Typography variant={'caption'}>{props.song.author}</Typography>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
      {props.buttons && (
        <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>{props.buttons}</Box>
      )}
    </Card>
  )
}

export default SongListItem
