import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { useAuthContext } from './hooks/useAuthContext'
import { appUrls } from './helpers/urls'
import Layout from './components/layouts/Layout'

function ProtectedRoute({ redirectPath = appUrls.login }) {
  const {
    state: { user, authIsReady }
  } = useAuthContext()

  if (!authIsReady) {
    return (
      <Layout>
        <CircularProgress />
      </Layout>
    )
  }

  if (!user) {
    return (
      <Navigate
        to={redirectPath}
        replace
      />
    )
  }

  return <Outlet />
}

export default ProtectedRoute
