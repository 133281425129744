import React, { FC, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { Divider, Stack, Typography } from '@mui/material'
import Layout from '../components/layouts/Layout'
import PlaylistItem from '../components/PlaylistItem'
import { useFirebasePlaylistsOfUser } from '../data/queryFunctions'
import { useAuthContext } from '../hooks/useAuthContext'
import { appUrls } from '../helpers/urls'
import { useLatestPlaylists } from '../hooks/useLatestPlaylists'
import LatestPlaylists from '../components/playlist/LatestPlaylists'

const PlaylistsPage: FC = () => {
  const navigate = useNavigate()

  const {
    state: { user }
  } = useAuthContext()

  if (!user) {
    navigate(appUrls.login)
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: playlists } = useFirebasePlaylistsOfUser(user!.uid)

  const { addLatestPlaylist } = useLatestPlaylists()

  const startPlaylist = (id: string, name: string) => {
    addLatestPlaylist(id, name)
    navigate(appUrls.playlist.withParams({ playlistId: id }))
  }

  const sortedPlaylists = useMemo(
    () => [...(playlists ?? [])].sort((a, b) => a.name.localeCompare(b.name)),
    [playlists]
  )

  return (
    <Layout>
      <Stack
        direction={'column'}
        gap={2}
      >
        <LatestPlaylists />

        <Divider />

        <Typography variant={'h5'}>Všetky playlisty</Typography>

        {sortedPlaylists.map((_) => (
          <PlaylistItem
            key={_.id}
            playlistId={_.id}
            name={_.name}
            startPlaylist={() => startPlaylist(_.id, _.name)}
          />
        ))}
      </Stack>
    </Layout>
  )
}
export default PlaylistsPage
