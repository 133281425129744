// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, DocumentData, CollectionReference } from '@firebase/firestore'
import { getAuth } from 'firebase/auth'
import Playlist from '../models/playlist'
import { ISong } from '../models/song'
import { ISlideshow } from '../models/slideshow'

const firebaseConfig = {
  apiKey: 'AIzaSyB2Vgd7UeNQ80YS6ca1ePm5Z0T-82ik_FY',
  authDomain: 'songbook-95e4b.firebaseapp.com',
  projectId: 'songbook-95e4b',
  storageBucket: 'songbook-95e4b.appspot.com',
  messagingSenderId: '56644075034',
  appId: '1:56644075034:web:5e7afe0821beb0f37e1ba3',
  measurementId: 'G-VK88Q8NHWZ'
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const projectAuth = getAuth()

const dbCollections = {
  playlists: 'playlists',
  songs: 'songs',
  slideshows: 'slideshows'
}

const createCollection = <T = DocumentData,>(collectionName: string) => {
  return collection(db, collectionName) as CollectionReference<T>
}

const playlistCollection = createCollection<Playlist>(dbCollections.playlists)
const songsCollection = createCollection<ISong>(dbCollections.songs)
const slideshowCollection = createCollection<ISlideshow>(dbCollections.slideshows)

export { projectAuth, playlistCollection, songsCollection, slideshowCollection }
