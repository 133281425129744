import React, { FC, ReactNode, useEffect } from 'react'
import { useInfiniteHits, useSearchBox } from 'react-instantsearch-hooks-web'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { BaseHit, Hit } from 'instantsearch.js/es/types/results'
import { ISong } from '../../models/song'

interface Props {
  searchQuery: string
  renderSongItem: (song: Hit<SongHit>) => ReactNode
}

export type SongHit = BaseHit & Omit<ISong, 'id'>

const OwnSongsSearchResults: FC<Props> = (props) => {
  const { refine } = useSearchBox()
  const { hits, showMore, isLastPage } = useInfiniteHits<SongHit>()

  useEffect(() => {
    refine(props.searchQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchQuery])

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        {hits.map((_) => (
          <Grid
            key={_.objectID}
            item
            xs={12}
            md={6}
          >
            {props.renderSongItem(_)}
          </Grid>
        ))}
      </Grid>

      {!isLastPage && (
        <Button
          onClick={showMore}
          variant={'contained'}
          sx={{ mt: 3 }}
        >
          Načítať viac
        </Button>
      )}
    </>
  )
}

export default OwnSongsSearchResults
